var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('NavBar',{staticClass:"nav-bar",attrs:{"iconColor":'#9B9B9B'}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("订单详情")])]),_c('div',{staticClass:"page-back"}),_c('div',{staticClass:"order-status"},[_c('img',{staticClass:"order-status-img",attrs:{"src":_vm.statusImgs || _vm.statusImg}}),_c('span',{staticClass:"status"},[_vm._v(_vm._s(_vm.detailInfo.status_str))])]),_c('AddressInfo',{attrs:{"detailInfo":_vm.detailInfo,"orderType":_vm.orderType,"locationId":_vm.locationId}}),_c('OrderCard',{attrs:{"detailInfo":_vm.detailInfo}}),_c('OrderInfo',{attrs:{"detailInfo":_vm.detailInfo,"orderType":_vm.orderType}}),_c('div',{staticClass:"operate-button"},[(
        _vm.orderType * 1 === 1 &&
        this.detailInfo.status === 1 &&
        this.detailInfo.deliver_type !== 3
      )?_c('div',{staticClass:"button-margin button"},[_c('van-button',{attrs:{"block":"","plain":"","round":""},on:{"click":_vm.selectAddress}},[_vm._v(" 修改地址 ")])],1):_vm._e(),_c('div',{staticClass:"button-min-box"},[(_vm.orderType * 1 === 1 && this.detailInfo.status === 1)?_c('div',{staticClass:"button-margin button",on:{"click":function($event){return _vm.clickBtn('del')}}},[_c('van-button',{attrs:{"block":"","plain":"","round":""}},[_vm._v("取消订单")])],1):_vm._e(),(
          _vm.orderType * 1 === 1 &&
          this.detailInfo.status === 2 &&
          this.detailInfo.shipping_status >= 2 &&
          this.detailInfo.deliver_type !== 3
        )?_c('div',{staticClass:"button-margin button",on:{"click":function($event){return _vm.clickBtn('logistics')}}},[_c('van-button',{attrs:{"block":"","plain":"","round":""}},[_vm._v("查看物流")])],1):_vm._e()]),(_vm.orderType * 1 === 1)?_c('div',{staticClass:"button"},[(this.detailInfo.status === 1)?_c('van-button',{staticClass:"immediately-pay",attrs:{"block":"","round":"","color":"linear-gradient(to right, #FE5876, #ED301D)"},on:{"click":function($event){return _vm.payment()}}},[_vm._v(" 立即付款 ")]):_vm._e(),(
          this.detailInfo.status === 2 &&
          (this.detailInfo.shipping_status >= 2 ||
            this.detailInfo.deliver_type === 3)
        )?_c('van-button',{attrs:{"block":"","round":"","color":"linear-gradient(to right,  #FE5876, #ED301D)"},on:{"click":_vm.setAffirmOrder}},[_vm._v(" 确认收货 ")]):_vm._e()],1):_c('div',{staticClass:"button"},[_c('div',{staticClass:"shipments"},[(
            this.detailInfo.status === 2 &&
            this.detailInfo.shipping_status < 2 &&
            this.detailInfo.deliver_type !== 3
          )?_c('van-button',{attrs:{"block":"","round":"","color":"linear-gradient(to right, #ED301D, #FE5876)"},on:{"click":function($event){return _vm.shipments()}}},[_vm._v(" 发货 ")]):_vm._e()],1)])]),_c('Shipments',{attrs:{"isShow":_vm.isShipments,"orderId":_vm.orderId},on:{"closePopup":_vm.closePopup,"startOrder":_vm.getOrderDetails}}),_c('SelectAddress',{attrs:{"isShow":_vm.isAddress},on:{"getAddress":_vm.getAddress}}),_c('PayOrder',{attrs:{"showPay":_vm.showPay,"orderId":_vm.orderId},on:{"closeOverlay":_vm.closeOverlay}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }