<template>
  <div class="order-info">
    <div class="info-list">
      <span class="info-lable">订单编号：</span>
      <span class="info-color">{{ detailInfo.used_sn }}</span>
      <span class="copy-btn">复制</span>
    </div>
    <div class="info-list">
      <span class="info-lable">创建时间：</span>
      <span class="info-color">{{ detailInfo.created_at }}</span>
    </div>
    <div class="info-list" v-if="detailInfo.pay_sn !== ''">
      <span class="info-lable">支付方式：</span>
      <span class="info-color">{{ detailInfo.pay_type_str }}</span>
    </div>
    <div class="info-list" v-if="detailInfo.pay_sn !== ''">
      <span class="info-lable">支付时间：</span>
      <span class="info-color">{{ detailInfo.pay_time }}</span>
    </div>
    <div class="info-list" v-if="detailInfo.shipping_status * 1 > 1">
      <span class="info-lable">配送方式：</span>
      <span class="info-color">{{ isDeliver(detailInfo.deliver_type) }}</span>
    </div>
    <div class="info-list" v-if="false">
      <span class="info-lable">自取时间：</span>
      <span class="info-color">{{ detailInfo.confirm_time }}</span>
    </div>
    <van-divider />
    <ContactService :orderType="orderType" :uId="userId"></ContactService>
  </div>
</template>

<script>
import ContactService from "@/components/contact-service/contact-service";
export default {
  name: "order-info",
  props: {
    detailInfo: {
      type: Object,
    },
    orderType: {
      type: Number,
    },
  },
  components: {
    ContactService,
  },
  computed: {
    userId() {
      let id = 0;
      console.log(this.orderType);
      if (this.orderType === 1) {
        id = this.detailInfo.sell_id;
      } else {
        id = this.detailInfo.buy_id;
      }
      return id;
    },
  },
  data() {
    return {};
  },
  methods: {
    isDeliver(num) {
      // 运费
      let str = "";
      if (num * 1 === 1) {
        str = "包邮";
      } else if (num * 1 === 2) {
        str = "到付";
      } else {
        str = "自提";
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
@fontSixteen: 16px;
.order-info {
  padding: 14px 12px;
  background-color: #fff;
  border-radius: 13px;
  margin-top: 12px;
  .info-list {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    font-size: @fontSixteen;
    &:nth-child(2n) {
      margin-bottom: 18px;
    }
    &:last-child {
      margin: 0;
    }
    .info-lable {
      width: 84px;
      margin-right: 24px;
    }
    .info-color {
      color: #6f6f6f;
      margin-right: 10px;
    }
    .copy-btn {
      display: block;
      font-size: 12px;
      color: #e61f18;
      border: 1px solid #e61f18;
      border-radius: 6px;
      width: 38px;
      height: 18px;
      text-align: center;
      line-height: 18px;
    }
  }
}
</style>
