<template>
  <div>
    <!-- 状态一 -->
    <div class="select-address-one" v-if="detailInfo.deliver_type * 1 !== 3">
      <van-icon
        name="location-o"
        size="20px"
        color="9B9B9B"
        class="location-icon"
      />
      <div class="address-info">
        <div class="user-info">
          <span class="user-name">{{ detailInfo.consignee || ""}}</span>
          <span class="phone">{{ detailInfo.consignee_tel || ""}}</span>
        </div>
        <div class="address">
          <span>{{ detailInfo.city_name || ""}}</span>
          <span>{{ detailInfo.address || ""}}</span>
        </div>
      </div>
    </div>
    <!-- 状态二 -->
    <div class="select-address-two" v-else>
      <div class="address-info">
        <div class="user-info">
          <span class="phone">{{ detailInfo.consignee_tel}} </span>
          <div class="one-key" @click="callPhone">
            <van-icon name="phone" />
            <a>一键拨号</a>
          </div>
          <div class="navigation" @click="showMap">
            <img
              src="~@/assets/used/the_map_icon@2x.png"
              class="navigation-icon"
            />
            <span>导航</span>
          </div>
        </div>
        <div class="address">
          <span>{{ detailInfo.city_name || "" }} </span>
          <span>{{ detailInfo.address || "" }}</span>
        </div>
      </div>
    </div>
<!--    <van-action-sheet-->
<!--      v-model="show"-->
<!--      :actions="actions"-->
<!--      cancel-text="取消"-->
<!--      close-on-click-action-->
<!--      @cancel="onCancel"-->
<!--      @select="onSelect"-->
<!--    />-->
    <Navigation ref="Navigation" v-if="showNavigation" :detailInfo="detailInfo" @closeNavigation="closeNavigation"></Navigation>
  </div>
</template>

<script>
import Navigation from '@/components/map/navigation'
import { txToBd } from "@/api";
import { Toast } from "vant";

export default {
  name: "address-info",
  props: {
    detailInfo: {
      type: Object,
    },
    orderType: {
      type: Number,
    },
    locationId: {
      type: Number,
    },
  },
  components: {
    Navigation,
  },
  watch: {},
  mounted() {
    // this.getAddressInfo();

  },
  data() {
    return {
      showNavigation:false,
      show: false,
      addressInfo: {},
      actions: [
        { name: "腾讯地图" },
        { name: "高德地图" },
        // { name: "百度地图" },
      ],
    };
  },
  methods: {
    closeNavigation(){
      this.showNavigation=false;
    },
    showMap() {
      this.showNavigation=true;
      this.$refs.Navigation.showMap();
    },
    async onSelect(action, index) {
      let url = "";
      if (index === 0) {
        url = await this.txMap();
      } else if (index === 1) {
        url = await this.gdMap();
      } else {
        url = await this.bdMap();
      }
      this.show = false;
      if (url === "") {
        return false;
      }
      console.log(url);
      window.location.href = url;
    },
    txMap() {
      let mapUrl_tx =
        "http://apis.map.qq.com/uri/v1/marker?marker=coord:" +
        this.detailInfo.lat +
        "," +
        this.detailInfo.lon +
        ";title:" +
        this.detailInfo.address +
        ";addr:" +
        this.detailInfo.city_name +
        this.detailInfo.address +
        "&referer=yellowpage";
      return mapUrl_tx;
    },
    gdMap() {
      let mapUrl_gd =
        "http://uri.amap.com/marker?position=108.958486,34.271454&name=" +
        inform.orgName +
        "&src=yellowpage&coordinate=gaode&callnative=1";
      return mapUrl_tx;
    },
    // async bdMap() {
    //   const res = await txToBd({
    //     ak: "7xsWbvhn9TkyHx9p7lukiO4blQZq5rIN",
    //     coords: this.detailInfo.lon + "," + this.detailInfo.lat,
    //   });
    //   if (res.result !== undefined && res.result.length > 0) {
    //     let lat = res.result[0].y;
    //     let lon = res.result[0].x;
    //     let mapUrl_bd =
    //       "http://api.map.baidu.com/marker?location=" +
    //       lat +
    //       "," +
    //       lon +
    //       "&title=" +
    //       this.detailInfo.address +
    //       "&content=" +
    //       this.detailInfo.city_name +
    //       this.detailInfo.address +
    //       "&output=html&src=yellowpage";
    //     return mapUrl_bd;
    //   } else {
    //     Toast.fail("地址信息转换失败");
    //     return "";
    //   }
    // },
    //取消弹出层
    onCancel() {
      this.show = false;
    },
    callPhone() {
      window.location.href = `tel:${this.detailInfo.consignee_tel}`;
    },
  },
};
</script>

<style lang="less" scoped>
.select-address-one {
  position: relative;
  padding: 14px 38px;
  background-color: #fff;
  border-radius: 13px;

  .location-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .user-info {
    font-size: 17px;
    font-weight: 500;

    .phone {
      font-weight: 400;
      color: #b9babe;
      margin-left: 6px;
    }
  }
}

.address {
  color: #6f6f6f;
  font-size: 16px;
  margin-top: 6px;
}

.select-address-two {
  position: relative;
  padding: 16px 14px;
  background-color: #fff;
  border-radius: 13px;

  .user-info {
    display: flex;

    .phone {
      font-size: 17px;
      margin-right: 10px;
    }

    .one-key {
      display: flex;
      align-items: center;
      color: #00c1ed;
      font-size: 13px;
      border: 1px solid #00c1ed;
      border-radius: 3px;
      padding: 0 8px 0 6px;
    }

    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 12px;
      right: 0;
      width: 70px;
      height: 26px;
      border-radius: 65px 0 0 65px;
      background: linear-gradient(to right, #fe4140, #fa292a);
      padding: 2px 8px;
      color: #fff;
      font-size: 14px;
      box-sizing: border-box;

      .navigation-icon {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
  }
}
</style>
