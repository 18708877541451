<template>
  <div class="service" @click="contactSeller">
    <img src="~@/assets/common/kefu_icon@2x.png" class="service-img" />
    <span class="service-text">{{
      orderType === 1 ? "联系卖家" : "联系买家"
    }}</span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    orderType: {},
    uId: {},
  },
  data() {
    return {};
  },
  methods: {
    contactSeller() {
      console.log("进入聊天获取的ID===", this.uId);
      this.$router.push({
        name: "information",
        query: {
          uId: this.uId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  display: flex;
  justify-content: center;
  align-items: center;
  .service-img {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .service-text {
    color: #aaaaaa;
    font-size: 16px;
  }
}
</style>