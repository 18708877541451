<template>
  <div class="page">
    <NavBar class="nav-bar" :iconColor="'#9B9B9B'">
      <div slot="title">订单详情</div>
    </NavBar>
    <div class="page-back"></div>
    <!-- 订单状态 -->
    <div class="order-status">
      <img :src="statusImgs || statusImg" class="order-status-img" />
      <span class="status">{{ detailInfo.status_str }}</span>
    </div>
    <!-- 地址选择 -->
    <AddressInfo
      :detailInfo="detailInfo"
      :orderType="orderType"
      :locationId="locationId"
    ></AddressInfo>
    <!-- 商品详情 -->
    <OrderCard :detailInfo="detailInfo"></OrderCard>
    <!-- 订单信息 -->
    <OrderInfo :detailInfo="detailInfo" :orderType="orderType"></OrderInfo>
    <!-- 底部操作按钮 -->
    <div class="operate-button">
      <div
        class="button-margin button"
        v-if="
          orderType * 1 === 1 &&
          this.detailInfo.status === 1 &&
          this.detailInfo.deliver_type !== 3
        "
      >
        <van-button block plain round @click="selectAddress">
          修改地址
        </van-button>
      </div>
      <!-- 删除订单和查看物流 -->
      <div class="button-min-box">
        <div
          class="button-margin button"
          @click="clickBtn('del')"
          v-if="orderType * 1 === 1 && this.detailInfo.status === 1"
        >
          <van-button block plain round>取消订单</van-button>
        </div>
        <div
          class="button-margin button"
          @click="clickBtn('logistics')"
          v-if="
            orderType * 1 === 1 &&
            this.detailInfo.status === 2 &&
            this.detailInfo.shipping_status >= 2 &&
            this.detailInfo.deliver_type !== 3
          "
        >
          <van-button block plain round>查看物流</van-button>
        </div>
      </div>
      <div class="button" v-if="orderType * 1 === 1">
        <van-button
          @click="payment()"
          v-if="this.detailInfo.status === 1"
          block
          round
          color="linear-gradient(to right, #FE5876, #ED301D)"
          class="immediately-pay"
        >
          立即付款
        </van-button>
        <van-button
          @click="setAffirmOrder"
          v-if="
            this.detailInfo.status === 2 &&
            (this.detailInfo.shipping_status >= 2 ||
              this.detailInfo.deliver_type === 3)
          "
          block
          round
          color="linear-gradient(to right,  #FE5876, #ED301D)"
        >
          确认收货
        </van-button>
      </div>
      <div class="button" v-else>
        <div class="shipments">
          <van-button
            @click="shipments()"
            v-if="
              this.detailInfo.status === 2 &&
              this.detailInfo.shipping_status < 2 &&
              this.detailInfo.deliver_type !== 3
            "
            block
            round
            color="linear-gradient(to right, #ED301D, #FE5876)"
          >
            发货
          </van-button>
        </div>
      </div>
    </div>
    <Shipments
      :isShow="isShipments"
      :orderId="orderId"
      @closePopup="closePopup"
      @startOrder="getOrderDetails"
    ></Shipments>
    <SelectAddress :isShow="isAddress" @getAddress="getAddress"></SelectAddress>
    <PayOrder
      :showPay="showPay"
      :orderId="orderId"
      @closeOverlay="closeOverlay"
    ></PayOrder>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import AddressInfo from "./moudles/address-info/address-info";
import OrderInfo from "./moudles/order-info/order-info";
import OrderCard from "./moudles/order-card/order-card";
import Shipments from "../components/shipments/shipments";
import PayOrder from "../pay-order";
import SelectAddress from "@/components/select-address/select-address";
import {
  cancelOrder,
  getOrderDetails,
  setAffirmOrder,
  setDelOrder,
  updateAddress,
} from "@/api/used";
import { Dialog, Toast } from "vant";
export default {
  name: "order-details",
  components: {
    NavBar,
    AddressInfo,
    OrderInfo,
    OrderCard,
    Shipments,
    SelectAddress,
    PayOrder,
  },
  data() {
    return {
      isAddress: false,
      isShipments: false,
      showPay: false,
      orderId: this.$route.query.orderId * 1 || 0,
      orderType: this.$route.query.orderType * 1 || 0,
      locationId: 0,
      detailInfo: {
        sell_info: {},
      },
      statusImg: require("@/assets/used/status_3_succeed.png"),
    };
  },

  mounted() {
    this.getOrderDetails();
    this.isIphone();
  },
  computed: {
    isSelectAddress() {
      let bool = false;
      if (this.detailInfo.status === 1 && this.orderType * 1 === 1) {
        bool = true;
      }
      return bool;
    },
    redBtnText() {
      let obj = {
        bool: false,
        text: "",
      };
      if (this.detailInfo.status === 1) {
        obj.bool = true;
        obj.text = "立即付款";
      } else if (this.detailInfo.status === 2) {
        obj.bool = true;
        obj.text = "确认收货";
      }
      return obj;
    },
    delOrder() {
      let status = 1;
      if (this.detailInfo.status === 1) {
        status = 1;
      } else if (this.detailInfo.status === 2) {
        status = 1;
      } else if (this.detailInfo.status === 3) {
        status = 3;
      }
      return status;
    },
    statusImgs() {
      let imgUrl = "";
      if (this.detailInfo.status === 1) {
        imgUrl = require("@/assets/used/status_1_succeed.png");
      } else if (this.detailInfo.status === 2) {
        if (this.detailInfo.shipping_status <= 1) {
          imgUrl = require("@/assets/used/status_1-1_succeed.png");
        } else {
          imgUrl = require("@/assets/used/status_2_succeed.png");
        }
      } else {
        imgUrl = require("@/assets/used/status_3_succeed.png");
      }
      return imgUrl;
    },
  },
  methods: {
    isIphone() {
      let container = document.querySelector(".operate-button");
      let isIphoneX =
        window.devicePixelRatio &&
        window.devicePixelRatio === 3 &&
        window.screen.width === 375 &&
        testUA("iPhone");
      if (isIphoneX) {
        check();
        window.onscroll = check();
      }
      function check() {
        // 处理lib-flexible放大viewport的情况
        let scale = window.innerWidth / window.screen.width;
        // 部分浏览器在滚动页面时会显示/隐藏工具栏，影响视口高度。在有底部工具栏的情况下，不做iPhoneX的fix。100为经验值
        if (window.screen.height - window.innerHeight / scale < 100) {
          document.body.classList.add("fix-iphonex-bottom");
          container.style.paddingBottom = 34 + "px";
        } else {
          document.body.classList.remove("fix-iphonex-bottom");
          container.style.paddingBottom = 0 + "px";
        }
      }
      function testUA(str) {
        return navigator.userAgent.indexOf(str) > -1;
      }
    },
    shipments() {
      //开启发货
      this.isShipments = true;
    },
    closePopup() {
      // 关闭发货
      this.isShipments = false;
    },
    async setAffirmOrder() {
      const res = await setAffirmOrder({ order_id: this.orderId });
      console.log(res);
      if (res.code === 1) {
        Toast.success("操作成功");
        this.$router.push({ name: "ascertain-order" });
      }
    },
    payment() {
      // 打开支付
      this.showPay = true;
    },
    closeOverlay() {
      // 关闭支付（回调）
      this.showPay = false;
    },
    selectAddress() {
      // 打开地址选择
      this.isAddress = true;
    },
    clickBtn(str) {
      if (str === "del") {
        Dialog.confirm({
          title: "提示",
          message: "你确定取消此订单吗？",
        })
          .then(() => {
            this.cancelOrder();
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push({
          name: "examine-logistics",
          query: { orderId: this.detailInfo.id },
        });
      }
    },
    async cancelOrder() {
      // 取消订单
      const res = await cancelOrder({ order_id: this.orderId });
      console.log(res);
      if (res.code === 1) {
        Toast.success("取消成功");
        this.$router.push({ name: "ascertain-order" });
      }
    },
    async getAddress(locationId) {
      // 关闭地址选择（回调）
      this.isAddress = false;
      if (locationId === undefined || locationId * 1 <= 0) {
        return false;
      }
      this.locationId = locationId;
      console.log(locationId);
      const res = await updateAddress({
        order_id: this.orderId,
        address_id: locationId,
      });
      // console.log("-------地址修改---------", res);
      if (res.code === 1) {
        this.getOrderDetails();
        Toast.success("地址修改成功");
      } else {
        Toast.fail(res.msg);
      }
    },
    async getOrderDetails() {
      // console.log("获取数据之前orderId----------", this.orderId);
      const res = await getOrderDetails({ order_id: this.orderId });
      // console.log("getOrderDetails--------", res);
      this.detailInfo = res.data;
    },
    async setDelOrder() {
      const res = await setDelOrder({ order_id: this.detailInfo.id });
      if (res.code === 1) {
        Toast.success("删除成功");
      } else {
        Toast.fail(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@fontSixteen: 16px;
.page {
  padding: 52px 12px 76px 12px;
}
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.page-back {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 168px;
  background: linear-gradient(to bottom, #f4f7ff, #f9f9f9);
}
.order-status {
  display: flex;
  align-items: center;
  padding: 24px 0;
  .order-status-img {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }
  .status {
    font-weight: 500;
    font-size: 23px;
  }
}
.operate-button {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 64px;
  z-index: 2000;
  background-color: #fff;
  border-top: 1px solid rgb(236, 236, 236);
  padding: 0 12px;
  .button-min-box {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .button-min-box {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 12px;
  }
}
/deep/.van-button--block {
  height: 46px;
}
/deep/.van-button__text {
  font-size: 17px;
}
.van-button--danger {
  border: 1px solid #ed301d;
}
.van-button--default {
  border: 1px solid #b9babe;
}
.button {
  display: flex;
  align-items: center;
  .immediately-pay {
    margin-left: 10px;
  }
}
.shipments {
  width: 116px;
}
.fix-iphonex-bottom {
  padding-bottom: 34px;
}
</style>
