<template>
  <van-popup
    v-model="isShow"
    :close-on-click-overlay="false"
    overlay-class="overlay"
    @click-overlay="clickOverlay"
  >
    <div class="shipments-card">
      <div class="title">发货</div>
      <div class="shipments">
        <span class="title-lable">物流商</span>
        <input
          type="text"
          class="input"
          v-model="expressName"
          placeholder="请选择物流商"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
        />
        <van-icon :name="true ? 'arrow-down' : 'arrow-up'" />
        <!-- 下拉选择 -->
        <ul class="select-logistics" ref="logistics">
          <li
            v-for="item in expressList"
            :key="item.id"
            class="list"
            @click="selectExpress(item)"
          >
            {{ item.shipping_name }}
          </li>
        </ul>
      </div>
      <div class="shipments">
        <span class="title-lable">订单号</span>
        <input
          type="text"
          v-model="express.shipping_sn"
          class="input"
          placeholder="请输入订单号"
        />
      </div>
      <div class="button-box">
        <div class="button">
          <van-button plain type="danger" block round @click="clickOverlay">
            取消
          </van-button>
        </div>
        <div class="button">
          <van-button type="danger" block round @click="confirm">
            确定
          </van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getExpressList, setOrderExpress } from "@/api/used";
import { Toast } from "vant";
export default {
  name: "shipments",
  props: {
    isShow: {
      type: Boolean,
    },
    orderId: {
      type: Number,
    },
  },
  data() {
    return {
      logistics: "",
      expressList: [],
      expressName: "",
      express: {
        order_id: "",
        shipping_id: "",
        shipping_sn: "",
      },
    };
  },
  mounted() {},
  watch: {
    isShow(newVale) {
      if (newVale) {
        this.getExpressList();
      }
    },
  },
  methods: {
    confirm() {
      // 确认
      let Reg = /^[A-Za-z0-9]+$/;
      if (!Reg.test(this.express.shipping_sn)) {
        Toast.fail("请输入正确订单号");
        return false;
      }
      this.express.order_id = this.orderId;
      if (this.verification()) {
        this.setOrderExpress();
        this.$emit("closePopup");
      }
      this.emptyFn();
    },
    clickOverlay() {
      this.$emit("closePopup");
    },
    onFocus() {
      // 打开物流选择框
      this.$refs.logistics.classList.add("logistics");
    },
    onBlur() {
      setTimeout(() => {
        this.$refs.logistics.classList.remove("logistics");
      }, 1);
    },
    selectExpress(data) {
      // 选择物流
      console.log(data);
      this.express = {
        order_id: this.orderId,
        shipping_id: data.id,
        shipping_sn: "",
      };
      this.expressName = data.shipping_name;
      this.$refs.logistics.classList.remove("logistics");
    },
    onInput() {
      this.expressName = "";
    },
    async getExpressList() {
      // 获取快递
      const res = await getExpressList();
      console.log(res);
      this.expressList = res.data;
    },
    async setOrderExpress() {
      // 发货
      const res = await setOrderExpress(this.express);
      console.log(res);
      if (res.code === 1) {
        Toast.success("发货成功");
        this.$emit("startOrder");
      } else {
        Toast.fail(res.msg);
      }
    },
    verification() {
      if (this.express.shipping_id === "") {
        Toast.fail("请选择物流");
        return false;
      } else if (this.express.shipping_sn === "") {
        Toast.fail("请输入单号");
        return false;
      }
      return true;
    },
    emptyFn() {
      // 清空
      this.express = {
        order_id: "",
        shipping_id: "",
        shipping_sn: "",
      };
      this.expressName = "";
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background-color: transparent;
}
.shipments-card {
  width: 322px;
  min-height: 264px;
  background-color: #fff;
  border-radius: 13px;
  padding: 23px 34px 18px 34px;
  box-sizing: border-box;
  .title {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
  }
  .shipments {
    display: flex;
    position: relative;
    padding: 10px 0;
    margin-top: 18px;
    border-bottom: 1px solid rgb(243, 243, 243);
    .title-lable {
      width: 76px;
      font-size: 16px;
      // margin-right: 34px;
    }
    .input {
      width: 58%;
      font-size: 16px;
      border: none;
      outline: none;
    }
    .select-logistics {
      position: absolute;
      top: 43px;
      left: 0;
      width: 246px;
      height: 0px;
      background-color: rgb(255, 255, 255);
      z-index: 2000;
      transition: all 0.3s;
      overflow: hidden;
    }
    .logistics {
      height: 150px;
      padding: 12px;
      box-sizing: border-box;
      transition: all 0.3s;
      overflow: auto;
      &:-webkit-scrollbar {
        display: none;
      }
      .list {
        padding: 6px 0;
        font-size: 16px;
        border-bottom: 1px solid rgb(240, 240, 240);
        &:active {
          background-color: rgb(240, 240, 240);
        }
      }
    }
  }
  .button-box {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    .button {
      width: 116px;
    }
  }
}

/deep/.van-button {
  height: 38px !important;
}
/deep/.van-button__text {
  font-size: 18px;
}
.van-button--danger {
  border: 1px solid #ed301d;
}
.van-button--default {
  border: 1px solid #b9babe;
}
</style>
