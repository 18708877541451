<template>
  <div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { txToBd } from "@/api";
import { Toast } from "vant";

export default {
  name: "navigation",
  props: {
    detailInfo: {
      type: Object,
    },
  },
  data() {
    return {
      show: true,
      actions: [
        { name: "腾讯地图" },
        { name: "高德地图" },
        // { name: "百度地图" },
      ],
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    showMap() {
      this.show = true;
    },
    async onSelect(action, index) {
      let url = "";
      if (index === 0) {
        url = await this.txMap();
      } else if (index === 1) {
        url = await this.gdMap();
      } else {
        // url = await this.bdMap();
      }
      this.show = false;
      if (url === "") {
        return false;
      }
      console.log(url);
      window.location.href = url;
    },
    txMap() {
      let mapUrl_tx =
        "http://apis.map.qq.com/uri/v1/marker?marker=coord:" +
        this.detailInfo.lat +
        "," +
        this.detailInfo.lon +
        ";title:" +
        this.detailInfo.address +
        ";addr:" +
        this.detailInfo.city_name +
        this.detailInfo.address +
        "&referer=yellowpage";
      return mapUrl_tx;
    },
    gdMap() {
      let mapUrl_gd =
        "http://uri.amap.com/marker?position=" +
        this.detailInfo.lon +
        "," +
        this.detailInfo.lat +
        "&name=" +
        this.detailInfo.city_name +
        this.detailInfo.address +
        "&src=yellowpage&coordinate=gaode&callnative=1";
      return mapUrl_gd;
    },
    // async bdMap() {
    //   const res = await txToBd({
    //     ak: "7xsWbvhn9TkyHx9p7lukiO4blQZq5rIN",
    //     coords: this.detailInfo.lon + "," + this.detailInfo.lat,
    //   });
    //   if (res.result !== undefined && res.result.length > 0) {
    //     let lat = res.result[0].y;
    //     let lon = res.result[0].x;
    //     let mapUrl_bd =
    //       "http://api.map.baidu.com/marker?location=" +
    //       lat +
    //       "," +
    //       lon +
    //       "&title=" +
    //       this.detailInfo.address +
    //       "&content=" +
    //       this.detailInfo.city_name +
    //       this.detailInfo.address +
    //       "&output=html&src=yellowpage";
    //     return mapUrl_bd;
    //   } else {
    //     Toast.fail("地址信息转换失败");
    //     return "";
    //   }
    // },
    //取消弹出层
    onCancel() {
      this.show = false;
      this.$emit("closeNavigation");
    },
  },
};
</script>

<style scoped>
</style>
