<template>
  <div class="order-card">
    <div class="title-row" @click="goToShop(detailInfo.sell_info.id)">
      <img :src="detailInfo.sell_info.head_img" class="shop-img" />
      <span class="title-text">{{ detailInfo.sell_info.nickname }}</span>
      <van-icon name="arrow" size="17" color="#9B9B9B" />
    </div>
    <div class="goods-info">
      <img :src="detailInfo.used_img || defaultImg" class="goods-img" />
      <div class="content-info">
        <div class="title-content">
          {{ detailInfo.used_name }}
        </div>
        <div class="price">
          <span class="small">￥</span>
          <span class="integer">{{ toInteger(detailInfo.price) || "0" }}</span>
          <span class="small">{{
            twoFloating(detailInfo.price) || ".00"
          }}</span>
        </div>
      </div>
      <div class="quantity">{{ "x1" }}</div>
    </div>
    <van-divider />
    <!-- 价格明细 -->
    <div class="price-detail">
      <div class="price-list">
        <span class="title-lable">商品总价</span>
        <span class="total-prices">
          ￥{{ toInteger(detailInfo.price) || "0"
          }}{{ twoFloating(detailInfo.price) || ".00" }}
        </span>
      </div>
      <div class="price-list">
        <span class="title-lable">实付金额</span>
        <span class="practical-pay">
          ￥{{ toInteger(detailInfo.price) || "0"
          }}{{ twoFloating(detailInfo.price) || ".00" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools";

export default {
  name: "order-card",
  props: {
    detailInfo: {
      type: Object,
    },
  },
  data() {
    return {
      defaultImg: require("@/assets/used/shop_logo.png"),
    };
  },
  methods: {
    goToShop(shopId) {
      this.$router.push({
        name: "seller-info",
        query: {
          shopId: shopId,
        },
      });
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
@fontSixteen: 16px;
.order-card {
  background-color: #fff;
  border-radius: 13px;
  padding: 14px;
  margin-top: 12px;
}
.title-row {
  position: relative;
  display: flex;
  align-items: center;
  .shop-img {
    display: block;
    width: 20px;
    height: 20px;
  }
  .title-text {
    font-size: 17px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 12px;
  }
}
.goods-info {
  position: relative;
  display: flex;
  margin-top: 14px;
  .content-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .title-content {
      height: 42px;
      font-size: 16px;
      padding-right: 30px;
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
    }
  }
  .quantity {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: #aaaaaa;
  }
  .goods-img {
    flex-shrink: 0;
    display: block;
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  .price {
    font-size: 12px;
    .integer {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.van-divider {
  margin: 12px 0;
}
.price-detail {
  .price-list {
    display: flex;
    justify-content: space-between;
    font-size: @fontSixteen;
    margin-bottom: 12px;
    &:last-child {
      margin: 0;
    }
    .practical-pay {
      color: #ed301d;
      font-weight: 500;
    }
  }
}
</style>
